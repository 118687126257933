import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"




const Cases = (props) => {
  return (

    <Layout>
      <SEO title={"Case Studies"} />

      <div className="rbs-section">
        <div className="rbs-box-a">
          <div className="content">
            <h1 className="title-xl mb-8">
              Case Studies</h1>
          </div>
        </div>
      </div>


      <div className="case-bg-01 p-16 pt-32 md:p-32">
        <div className="text-white case-grid">
          <div>
            <h3 className="title">taju.space</h3>
            <p className="body-xl opacity-50">Personal multimedia space</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">taju.space is a personalised multimedia room that fits in your back yard. A truly innovative offering in a fast growing market segment fueled by the C-19 crisis. We helped taju.space kickstart their brand with the remote brand sprint. The fresh visual look, and a simple landingpage enabled our client to launch the taju sub-brand marketing campaign, test the reaction of their target audience and get the first customers interested, even before actually building the first unit.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case01.childImageSharp.fluid} />
        </div>

      </div>

      <div className="case-bg-03 p-16 pt-32 md:p-32">
        <div className="text-white case-grid">
          <div>
            <h3 className="title">Blue Key</h3>
            <p className="body-xl opacity-50">Privacy-first 2FA app</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">We helped Blue Key 2FA authentication app to launch their first brand identity in order to attract initial financing and gain the attention of the open-source cryptography community. After the remote brand sprint, Blue Key partnered up with our sister company <a href="https://taru.io" target="_blank" className="link" rel="noreferrer">TARU</a> to prtotype designs for their iOS app and the marketing landing page.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case03.childImageSharp.fluid} />
        </div>

      </div>


      <div className="case-bg-04 p-16 pt-32 md:p-32">
        <div className="text-white case-grid">
          <div>
            <h3 className="title">monologue</h3>
            <p className="body-xl opacity-50">Diary for Productivity</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">The monologue app introduces a revolutionary approach to logging your daily activities for getting more done. It lets the user track her work as well as reflect upon it and be more mindful along the way. Starting by writing down your commitment creates momentum. RBS helped them launch a unique visual identity. Among else, solid typography and icons were designed, to communicate the ease of using this wonderful app.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case02.childImageSharp.fluid} />
        </div>

      </div>


      <div className="case-bg-02 p-16 pt-32 md:p-32">
        <div className="text-white case-grid">
          <div>
            <h3 className="title">Abacus</h3>
            <p className="body-xl opacity-50">Redefining property management</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">Abacus is on a mission to reinvent the commercial real estate management market. A fair amount of work in this conservative sector is still carried out using Excel spreadsheets other dated methods. Abacus will change that. We helped them build their very first visual identity programme, which has proved to be an invaluable asset for onboarding and attracting the trust of the large asset management funds.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case04.childImageSharp.fluid} />
        </div>

      </div>

      <div className="case-bg-05 p-16 pt-32 md:p-32">
        <div className="text-white case-grid">
          <div>
            <h3 className="title">Callista</h3>
            <p className="body-xl opacity-50">Outstanding Business Software</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">Callista is a one stop shop for technical and business development needs. We helped them quickly refresh their brand through the RBS process. Utilising their updated brand helped Callista significantly grow their quarter on quarter earnings only shortly after applying the new designs.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case05.childImageSharp.fluid} />
        </div>

      </div>





      <CTAStart />

    </Layout>
  )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    case01: file(relativePath: { eq: "case-01.png" }) {
      ...fluidImage
    }
    case02: file(relativePath: { eq: "case-02.png" }) {
      ...fluidImage
    }
    case03: file(relativePath: { eq: "case-03.png" }) {
      ...fluidImage
    }
    case04: file(relativePath: { eq: "case-04.png" }) {
      ...fluidImage
    }
    case05: file(relativePath: { eq: "case-05.png" }) {
      ...fluidImage
    }
  }
`

export default Cases;